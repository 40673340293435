<template>
<div>
    <div class="StoreBox">
        <div class="StoreMain">
             <div class="StoreLeft">
                 <img :src="StoreInfo.postPicture" alt="">
             </div>     
             <div class="StoreRight">
                <p>{{StoreInfo.postAlias}}</p>
                <p>上架{{StoreInfo.count}}种商品 | 满{{StoreInfo.postage}}免运费</p>
             </div>
        </div>
    </div>
    <div class="LabelBox">
      <ul>
        <li  
          v-for="(item, index) in LabelList"
          :key="index"
          :class="{ active: Boolean(Labelcheck === index) }"  
          @click="LabelFun(index)">
           {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="goodsList" v-if="searchProducts!=[]">
        <div v-if="total==0">
            <p class="NullBox"></p>
        </div>
        <div v-else>
            <shopItem
                v-for="(item, index) in searchProducts"
                :key="index"
                class="goods"
                :info="item"
            ></shopItem>
            <div class="clear"></div>

            <!-- 全部商品时显示分页 -->
              <div class="pageBox" v-if="Labelcheck===0">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :hide-on-single-page="false"
                  prev-text="上一页"
                  next-text="下一页"
                  @current-change="handleCurrentChange"
                  :current-page="current"
                  :page-size="size"
                  :total="total"
                >
                </el-pagination>
              </div>
        </div>

    </div>
    <div class="goodsList" v-else>
        <p class="NullBox">敬请期待</p>
    </div>
   
</div>
  
</template>
<style lang="less">
@import "~style/theme.less";
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: @theme;
  color: #fff;
}
/* 上一页，下一页样式 */
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  background: #fff;
  color: #666;
  padding: 2px;
  box-sizing: border-box;
}

/* 每个页码的样式 */
.el-pagination.is-background .el-pager li {
  background: #fff;
  color: #666;
  padding: px;
  box-sizing: border-box;
}
.el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
  color: @theme;
  font-size: 14px;
}
</style>
<style lang="less" scoped>
@import "~style/theme.less";
.pageBox {
  float: left;
  width: 100%;
  .el-pagination {
    padding-top: 40px;
    text-align: center;
    float: right;
  }
}
.CopBox{
  width: 1200px;
   margin:0px auto;
   height: 310px;
   .getCoupon{
      width: 100%;
   }
}

.StoreBox{
     width: 100%;
     height: 130px;
     background: #fff;
     border-top: 1px solid #F6F6F6;
     border-bottom: 1px solid #F6F6F6;
     .StoreMain{
          width: 1200px;
         margin:0px auto;
         .StoreLeft{
             float: left;
             img{
                //  border: 1px dotted #ccc;
                //  margin: 20px 20px 0 0;
                //  width: 90px;
                //  height: 90px;
                    // border: 3px dotted #ccc;
                    margin: 20px 20px 0 0;
                    width: 84px;
                    height: 84px;
             }
         }
         .StoreRight{
              float: left;
             margin-top:20px;
             p:nth-child(1){
                 color: #333333;
                 font-size:22px;
                 line-height: 50px;
                 font-weight: bold;
             }
             p:nth-child(2){
                 color: #666;
                    font-size:16px;
                 line-height:30px;
             }
         }
     }
}
.LabelBox{
  width: 100%;
  height:50px;
  background: #fff;
  margin-bottom: 20px;
  ul{
     width: 1200px;
     margin:0px auto;
     line-height:50px;
     li{
       float: left;
        padding:0  20px;
        text-align: center;
        font-size: 16px;
        color: #333;
        font-weight: bold;
        &.active{
           cursor: pointer;
          color:#F43C38 ;
          border-bottom:2px solid #F43C38 ;
        }
        &:hover{
          cursor: pointer;
          color:#F43C38 ;
          border-bottom:2px solid #F43C38 ;
        }
      }
   
  }
}
.clear{
  clear: both;
}
 .goodsList {
    height: auto;
    min-height: 228px;
    background: #f6f6f6;
    padding-bottom:50px;
    width: 1200px;
    margin:0px auto;
    .goods:nth-child(5n + 0) {
      margin-right: 0px;
    }
    .NullBox{
      font-size: 30px;
      text-align: center;
      width: 100%;
      line-height: 300px;
      height: 300px;
      background:url("data:image/svg+xml,%3Csvg width='130' height='130' xmlns='http://www.w3.org/2000/svg'%3E%3Ctext x='50%25' y='50%25' font-size='14' fill='%23a2a9b6' font-family='system-ui, sans-serif' text-anchor='middle' transform='rotate(-45, 100 100)' dominant-baseline='middle'%3E药百万%3C/text%3E%3C/svg%3E");
      }
  }
</style>

<script>
const shopItem = () => import("components/index/shopItem.vue");
import {
  // GetNewArrivalPage,GetIdNewArrivalPage,//新品上市
  StoreInfo,//获取店铺信息
  GetProductsByPostId,//店铺推荐商品
  GetProductsPage//全部商品
} from "api/product.js";
export default {
  name:"GetCoupon",
   data() {
      return {
            total: 0,
            current: 1, //当前页
            size: 10, //每页数量
            StoreInfo:'',
            searchProducts: [], //初始商品数据
            Labelcheck: 0,
            LabelList:[
                 {
                  name: "全部商品"
                },
                  {
                  name: "推荐商品"
                },
            ]
      }
   },
   methods:{
    //  分页
     handleCurrentChange(val) {
      this.current = val;
       if(this.Labelcheck==1){
          //  推荐商品
          // alert('推荐商品 不需要分页')
          // this.RecommendFun()
        }else{
          // alert('全部商品')
           // 全部商品
          this.AllProductFun()
        }
      
    },
      //  推荐商品
      RecommendFun(){
         GetProductsByPostId(this.$route.query.postId).then((res) => {
          const records = res.data.data;
          this.searchProducts = records;

          console.log(records)
           this.total = Number(res.data.length);
             // 关闭loading
          this.$loading().close();
        });
      },

LabelFun(index){
    this.Labelcheck = index;
    if(this.Labelcheck==1){
      this.RecommendFun()
    }else{
      this.AllProductFun()
    }
},
      // 全部商品
      AllProductFun(){
        let  params={
          postId:this.$route.query.postId,
          current: this.current, //当前页
          size:this.size, //每页数量
        }
        GetProductsPage(params).then(res=>{
             const records = res.data.data.records;
            this.searchProducts = records;
           this.total = Number(res.data.data.total);
           console.log('this.searchProducts',this.searchProducts)
             // 关闭loading
          this.$loading().close();
        })
      },
  
        // 店铺信息
        StoreInfoFun(){
                StoreInfo(this.$route.query.postId).then((res) => {
                  const records = res.data.data;
                  console.log(records)
                  this.StoreInfo=records
                 
                });
        }


   },
   created(){
      this.AllProductFun()
       this.StoreInfoFun()
    },
  components:{
      shopItem
  } 
};
</script>
